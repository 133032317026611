<template>
  <div>
    <v-card>
      <v-card-title>E-Mail Ausgang</v-card-title>
      <v-data-table
          :headers="headers"
          :items="outgoingEmails"
          :options.sync="options"
          :server-items-length="totalEmailCount"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
          disable-sort
          @dblclick:row="rowDoubleClicked"
      ></v-data-table>
    </v-card>

    <v-dialog v-model="currentEmailDialogOpen" width="1000">
      <v-card>
        <v-card-title>{{ currentEmail.subject }}</v-card-title>
        <v-card-text class="black--text">
          <div v-html="currentEmail.content"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: "OutgoingEmailsTable",
  data() {
    return {
      options: {},
      outgoingEmails: [],
      totalEmailCount: 0,
      loading: true,
      currentEmail: {},
      currentEmailDialogOpen: false,
      // static fields
      headers: [
        { text: "Empfänger:", value: "recipientAddress" },
        { text: "Betreff:", value: "subject" },
        { text: "Template:", value: "templateName" },
        { text: "Von:", value: "senderAddress" },
        { text: "Prio:", value: "highPrio" },
        { text: "Hold:", value: "hold" },
        { text: "Gesendet:", value: "sentAt" }
      ],
      itemsPerPageOptions: [20, 50, 100]
    };
  },
  mounted() {
    this.fetchOutgoingEmails();
  },
  methods: {
    fetchOutgoingEmails() {
      let pageNumber = this.options["page"];
      let itemsPerPage = this.options["itemsPerPage"];
      this.loading = true;
      this.$store.dispatch("getRequestPromise", "outgoing-emails/page/" + pageNumber + "/" + itemsPerPage).then(response => {
        this.totalEmailCount = response["totalElements"];
        this.outgoingEmails = response["outgoingEmails"];
      }).finally(() => {
        this.loading = false;
      });
    },
    rowDoubleClicked(mouseEvent, row) {
      this.viewEmail(row.item);
    },
    viewEmail(email) {
      this.currentEmail = email;
      this.currentEmailDialogOpen = true;
    }
  }
};
</script>

<style scoped>

</style>