<template>
  <v-main>
    <v-container class="mt-4">
      <OutgoingEmailsTable class="mt-4"></OutgoingEmailsTable>
    </v-container>
  </v-main>
</template>

<script>
import OutgoingEmailsTable from "@/components/OutgoingEmailsTable";
import moment from "moment";
export default {
  name: "OutgoingEmails",
  components: { OutgoingEmailsTable },
  data() {
    return {

    };
  },
  filters: {
    formatDateTime: function(value) {
      return new Date(value).toLocaleDateString("de-DE", {
        weekday: "long",
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      }) + " Uhr";
    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style scoped>

</style>